import './PaymentFailure.css';

function PaymentFailure() {
  return (
    <>
      <h1>Hello , you're PaymentFailure</h1> 
    </>
  );
}

export default PaymentFailure;
