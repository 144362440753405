import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyB0SjMGLH_Fctm507D3tSCH5xsbEs-7JIg",
    authDomain: "project-vault-b6e5e.firebaseapp.com",
    projectId: "project-vault-b6e5e",
    storageBucket: "project-vault-b6e5e.appspot.com",
    messagingSenderId: "402026939963",
    appId: "1:402026939963:web:9c91464186b45750533e1e",
    measurementId: "G-YMPK5GZT22"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);